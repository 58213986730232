.header__title {
    @include make-col(12);
    margin: 130px 0 50px 0;
    @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
        margin: 125px auto 50px auto;
    }
    .title--lg,
    .title--md {
        position: relative;
        &:after {
            position: absolute;
            content: "";
            width: 400px;
            height: 100px;
            background-image: url('./../images/element.svg');
            background-repeat: no-repeat;
            background-size: contain;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -9;
        }
        @media (min-width: 480px) {
            &:after {
                left: 0;
            }
        }
        @include media-breakpoint-up(md) {
            &:after {
                width: 640px;
                height: 160px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.confirm-order {
    .header__title {
        margin-bottom: 0;
    }
    .btn--green {
        margin: 25px auto;
        @include media-breakpoint-up(sm) {
            margin: 50px auto;
        }
    }
}
