@font-face {
    font-family: 'Averta-Bold';
    src: url('../fonts/Averta-Bold.woff');
    src: url('../fonts/Averta-Bold.woff2') format('woff2'),
         url('../fonts/Averta-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Averta-Light';
    src: url('../fonts/Averta-Light.woff');
    src: url('../fonts/Averta-Light.woff2') format('woff2'),
         url('../fonts/Averta-Light.woff') format('woff');
}

@font-face {
    font-family: 'Averta-Regular';
    src: url('../fonts/Averta-Regular.woff');
    src: url('../fonts/Averta-Regular.woff2') format('woff2'),
         url('../fonts/Averta-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Averta-Semibold';
    src: url('../fonts/Averta-Semibold.woff');
    src: url('../fonts/Averta-Semibold.woff2') format('woff2'),
         url('../fonts/Averta-Semibold.woff') format('woff');
}
