.checkout {
	padding-bottom: 100px;
}

.checkout__product {
	margin-bottom: 50px;
	@include make-col(12);
	@include media-breakpoint-up(md) {
		@include make-col(5);
	}
	@include media-breakpoint-up(lg) {
		@include make-col(3.5);
		@include make-col-offset(1.5);
	}
}

.form-checkout {
	@include make-col(12);
	@include media-breakpoint-up(md) {
		@include make-col-offset(1);
		@include make-col(5);
	}
	@include media-breakpoint-up(lg) {
		@include make-col(4.5);
	}
}

.form-group,
.field,
.gfield {
	width: 100%;
	margin-bottom: 25px;
	display: flex;
	flex-direction: column;
	@include media-breakpoint-up(md) {
		margin-bottom: 35px;
	}
	.form-group__label,
	.gfield_label {
		@include averta-semibold();
		font-size: 1.5rem;
		line-height: 1.4;
		margin-bottom: 10px;
		@include media-breakpoint-down(xs) {
			margin-bottom: 7px;
		}
	}
}

.form-group__input,
.ginput_container_text input {
	width: 100%;
	line-height: 1;
	border: 1px solid #bac3d1;
	padding: 19px 25px;
	@include averta-light();
	font-size: 1.4rem;
	-webkit-appearance: none;
	border-radius: 3px;
	resize: none;
	display: block;
	color: $dark-grey;
	@include media-breakpoint-down(xs) {
		padding: 15px;
	}
	&:focus {
		border: 1px solid $blue;
		box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
		outline: none;
	}
	::placeholder {
		color: rgba(110, 110, 110, 0.4);
		line-height: normal;
	}
}

.form-group__txt {
	@include averta-bold();
	font-size: 2.4rem;
	line-height: 1.2;
	margin-bottom: 30px;
	padding-top: 15px;
	@include media-breakpoint-up(md) {
		margin-bottom: 40px;
	}
}

.gfield_error {
	input,
	textarea,
	.selectize-input {
		border-bottom: 2px solid $color-error;
	}
	.validation_message,
	.validation_error {
		@include averta-light();
		color: $color-error;
		font-size: 1.4rem;
		line-height: 1.2;
		padding-top: 10px;
		width: 100%;
	}
}

.form-group--address,
.form-group--address2 {
	display: flex;
	flex-wrap: wrap;
}

.form-group--address {
	.field {
		width: 100%;
		&:first-child {
			flex: 1;
			margin-right: 10px;
		}
		&:last-child {
			width: 120px;
		}
	}
}

.form-group--address2 {
	.field {
		width: 100%;
		&:first-child {
			width: 120px;
			margin-right: 10px;
		}
		&:last-child {
			flex: 1;
		}
	}
}

.form-group--payment {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
	position: relative;
	input[type='radio'] {
		position: absolute;
		left: -2000px;
		&:not(:checked) + label {
			filter: grayscale(1);
			opacity: 0.5;
			.payment-option .option-footer {
				border-top: 1px solid rgba(27, 40, 60, 0.5);
				background-color: $color-white;
				color: $primary-color;
			}
		}
	}
	.field {
		width: 50%;
		border-radius: 15px;
		box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
		margin-right: 15px;
		margin-bottom: 0;
		&:last-child {
			margin-right: 0;
		}
		@include media-breakpoint-up(md) {
			margin-right: 25px;
		}
	}
	.payment-option {
		cursor: pointer;
		width: 100%;
		height: 100%;
		margin-bottom: 0;
		.option-head {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 35px 10px;
			text-align: center;
			.icon {
				width: 60px;
				height: 60px;
				margin-top: 10px;
			}
		}
		.option-footer {
			width: 100%;
			height: 70px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border-radius: 0px 0px 15px 15px;
			background-color: $blue;
			color: $color-white;
			.price-label {
				font-size: 2rem;
				line-height: 1.2;
				@include averta-bold();
			}
		}
	}
}

.field--ideal,
.form-group--select {
	display: none;
}

.form-payment {
	@include make-col(12);
	margin: 25px 0 50px 0;
	@include media-breakpoint-up(md) {
		@include make-col(5);
		@include make-col-offset(3.5);
		margin-bottom: 100px;
	}
}

.form-group--select {
	margin-bottom: 50px;
}

.form-group__selector select {
	width: 100%;
	border: 1px solid #bac3d1;
	padding: 15px;
	@include averta-light();
	font-size: 1.4rem;
	-webkit-appearance: none;
	border-radius: 3px;
	resize: none;
	display: block;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPiAgPHBhdGggZD0iTTggLjk0Nkw0IDUgMCAuOTQ2LjkzMyAwIDQgMy4xMDggNy4wNjcgMHoiIGZpbGw9IiM4NDg0ODQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
	background-size: 8px 5px;
	background-repeat: no-repeat;
	background-position: right 19px center;
	&:focus {
		border: 1px solid $blue;
		box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
		outline: none;
	}
	::placeholder {
		color: $dark-grey;
		line-height: normal;
		opacity: 0.4;
	}
}

.form-group--checkbox {
	position: relative;
	input[type='checkbox'] + label:before {
		content: '';
		cursor: pointer;
		position: absolute;
		left: 0;
		width: 22px;
		height: 22px;
		border-radius: 3px;
		background-color: $color-white;
		border: 1px solid $grey;
	}
	input[type='checkbox']:checked + label:before {
		background-color: $blue;
		content: url(../images/icon_check_checkbox.svg);
		border: 1px solid $blue;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	input[type='checkbox']:focus + label::before {
		outline: 0;
	}
	.checkbox-label {
		@include averta-light();
		font-size: 1.4rem;
		margin-left: 37px;
		display: flex;
		align-items: center;
		cursor: pointer;
		a {
			display: inline;
			text-decoration: underline;
		}
	}
	input[type='checkbox'] {
		position: absolute;
		left: -2000px;
	}
}

.form__note {
	@include averta-light();
	color: $dark-grey;
	font-size: 1.4rem;
	line-height: 1.2;
	padding-top: 25px;
	width: 100%;
	text-align: center;
}

.gform_fields {
	@include reset-list();
}

.gform_validation_container {
	display: none;
}

.validation_error {
	display: none;
}

.parsley-errors-list {
	color: $color-error;
	font-size: 14px;
	line-height: 1.43;
	@include averta-light();
	@include reset-list();
	margin-top: 5px;
	text-align: center;
}
