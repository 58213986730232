/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "~bootstrap/scss/bootstrap-grid.scss";
@import "~bootstrap/scss/bootstrap-reboot.scss";
// @import "~bootstrap/scss/utilities/_embed.scss";

//Voor Modals
// @import "~bootstrap/scss/_modal.scss";