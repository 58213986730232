// Mixins

@mixin reset-list() {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin overflow-elipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin object-fit($fit: fill, $position: null){
	-o-object-fit: $fit;
		object-fit: $fit;
	@if $position {
		-o-object-position: $position;
			object-position: $position;
	font-family: 'object-fit: #{$fit}; object-position: #{$position}';
	} @else {
		font-family: 'object-fit: #{$fit}';
	}
}


@mixin averta-light() {
	font-family: $averta-Light;
    font-weight: normal;
}

@mixin averta-regular() {
	font-family: $primary-font;
    font-weight: normal;
}

@mixin averta-semibold() {
	font-family: $averta-Semibold;
    font-weight: normal;
}

@mixin averta-bold() {
	font-family: $averta-Bold;
    font-weight: normal;
}