.hamburger {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.hamburger__box {
    display: block;
    position: relative;
    width: 23px;
    height: 16px;
    cursor: pointer;
}

.hamburger-inner {
    display: block;
    position: absolute;
    background-color: $primary-color;
    width: 17px;
    height: 2px;
    border-radius: 1.5px;
    top: 50%;
    transform: translateY(-50%);
    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: $primary-color;
        width: 17px;
        height: 2px;
        border-radius: 1.5px;
    }
    &::before {
        top: -5px;
    }
    &::after {
        bottom: -5px;
    }
}

.btn-collapse {
    &:focus{
        outline: none;
    }
    .hamburger-inner {
        transition-duration: 0.100s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        &::before {
        transition: top 0.100s 0.12s ease,
                    opacity 0.100s ease;
        }
        &::after {
        transition: bottom 0.100s 0.12s ease,
                    transform 0.100s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }
    &.is-active {
        .hamburger-inner {
            transform: rotate(45deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.100s ease,
                            opacity 0.100s 0.12s ease;
            }
            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.100s ease,
                            transform 0.100s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}