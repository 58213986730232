button:focus {
	outline: none;
}

.btn--extern {
	@include averta-bold();
	font-size: 1.4rem;
	background-color: $blue;
	color: $color-white;
	display: flex !important;
	align-items: center;
	height: 40px;
	padding: 0 20px;
	border: 0;
	border-radius: 32.5px;
	margin-top: 25px;
	width: fit-content;
	transition: all 300ms ease-in-out;
	.icon {
		width: 1rem;
		height: 1rem;
		margin-left: 10px;
	}
	@include hover-focus-active {
		color: $color-white;
		background-color: $blue-hover;
	}
	@include media-breakpoint-up(sm) {
		font-size: 1.6rem;
	}
}

.btn--intern {
	@include averta-bold();
	font-size: 1.4rem;
	.icon {
		width: 1.2rem;
		height: 1.2rem;
		margin-left: 5px;
		transition: margin 300ms ease-in-out;
	}
	@include media-breakpoint-up(sm) {
		font-size: 1.6rem;
	}
	@include hover-focus-active {
		.icon {
			transition: margin 300ms ease-in-out;
			margin-left: 10px;
		}
	}
}

.btn--green {
	@include averta-bold();
	font-size: 1.4rem;
	background-color: $green;
	color: $color-white;
	margin: 0 auto;
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 20px;
	border-radius: 32.5px;
	width: fit-content;
	border: none;
	transition: all 300ms ease-in-out;
	text-align: center;
	line-height: 1;
	@include hover-focus-active {
		background-color: $green-hover;
		color: $color-white;
	}
	@include media-breakpoint-up(md) {
		font-size: 1.6rem;
	}
}

.btn--green-lg {
	margin-top: 35px;
	height: 53px;
}

.btn--fullWidth {
	width: 100%;
	justify-content: center;
}

.btn--product {
	@include averta-regular();
	font-size: 1.3rem;
	background-color: $color-white;
	border: 1px solid $primary-color;
	color: $primary-color;
	opacity: 0.3;
	display: flex;
	align-items: center;
	height: 34px;
	padding: 0 10px;
	border-radius: 19px;
	width: fit-content;
	@include hover-focus-active {
		color: $color-white;
		border: 1px solid $blue;
		background-color: $blue;
		outline: none;
		opacity: 1;
		transition: All 300ms ease-in-out;
	}
	@include media-breakpoint-up(md) {
		font-size: 1.8rem;
		padding: 0 20px;
		height: 40px;
	}
}

.buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
	@include make-col(12);
	@include media-breakpoint-up(md) {
		@include make-col(8);
		@include make-col-offset(2);
		margin-bottom: 100px;
	}
	@include media-breakpoint-up(lg) {
		@include make-col(6);
		@include make-col-offset(3);
	}
}

.btn--prices-js {
	@include averta-bold();
	font-size: 1.4rem;
	margin-left: 15px;
	white-space: nowrap;
	@include media-breakpoint-up(sm) {
		font-size: 1.6rem;
		margin-left: 30px;
	}
	.icon {
		width: 1.2rem;
		height: 1.2rem;
		margin-left: 5px;
		transition: margin 300ms ease-in-out;
	}
}

.no-margin {
	margin: 0;
}
