@import "bootstrap.scss";
@import "~flickity/dist/flickity.css";

// Common
@import "common/variables";
@import "common/mixins";
@import "common/global";
@import "common/wysiwyg";
@import "common/fonts";

// Layouts
@import "layouts/header";
@import "layouts/footer";
@import "layouts/standard";
@import "layouts/404";
@import "layouts/faq";

// Partials
@import "partials/buttons";
@import "partials/section";
@import "partials/hamburger";
@import "partials/header-title";
@import "partials/video";
@import "partials/products";
@import "partials/form";
@import "partials/basket";

