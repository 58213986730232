.product__sum {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 15px;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
    margin-right: 15px;
    &:last-child {
        margin-right: 0;
    }
}