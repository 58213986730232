// Variables//font
$averta-Bold: 'Averta-Bold', sans-serif;
$averta-Light: 'Averta-Light', sans-serif;
$primary-font: 'Averta-Regular', sans-serif;
$averta-Semibold: 'Averta-Semibold', sans-serif;

//colors
$primary-color: #1b283c;
$primary-hover: #1B283C;
$color-white: #fff;
$color-black: #000;
$color-error: #f23636;

$grey: #ebedf3;
$dark-grey: #6e6e6e;
$green: #37b764;
$green-hover: #24AD54;
$blue: #497bd0;
$blue-hover: #3369C4;

$d: 14px;
$grid-columns: 12;
$grid-gutter-width: $d;