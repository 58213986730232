html {
	-webkit-font-smoothing: antialiased;
	font-size: 62.5%;
}

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

// Placeholder form input verticaal centreren op fysiek mobiel apparaat
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	line-height: normal;
}

body {
	@include averta-regular();
	overflow-x: hidden;
}

a {
	outline: 0;
	display: block;
	color: $primary-color;
	@include hover-focus-active {
		color: $primary-color;
		text-decoration: none;
	}
}

p {
	margin: 0;
}

blockquote {
	text-align: left;
	@include media-breakpoint-up(sm) {
		text-align: center;
	}
}

.container {
	padding: 0;
	@include media-breakpoint-up(sm) {
		padding: 0 15px;
	}
	@include media-breakpoint-up(lg) {
		max-width: 1230px;
	}
}

.section__row {
	@include make-row();
}

.section__column {
	padding-left: 25px;
	padding-right: 25px;
	@include media-breakpoint-up(sm) {
		@include make-col-ready();
	}
}

.section__column--btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.center {
	text-align: left;
	@include media-breakpoint-up(md) {
		text-align: center;
	}
}

.margin-top {
	margin-top: 25px !important;
}

.title--lg {
	@include averta-bold();
	font-size: 3rem;
	line-height: 1.2;
	margin: 0;
	@include media-breakpoint-up(md) {
		font-size: 5rem;
	}
}

.title--md {
	@include averta-bold();
	font-size: 2.2rem;
	line-height: 1.2;
	margin-bottom: 18px;
	@include media-breakpoint-up(md) {
		font-size: 4rem;
		margin-bottom: 50px;
	}
}

.title--sm {
	@include averta-bold();
	font-size: 2.2rem;
	line-height: 1.2;
	@include media-breakpoint-up(md) {
		font-size: 3.2rem;
	}
}

.title--xs {
	@include averta-bold();
	font-size: 2rem;
	line-height: 1.2;
}

.bg--blue {
	background-color: $primary-color;
}

.bg--round {
	border-radius: 0px 0px 15px 15px;
	background-color: $primary-color;
	color: $color-white;
}

.bg--grey {
	background-color: $grey;
}

.no-padding {
	padding: 0;
}

.subtext {
	@include averta-light();
	font-size: 1.6rem;
	line-height: 1.2;
	text-align: left;
	margin: 20px 0 50px 0;
	color: $dark-grey;
	@include media-breakpoint-up(md) {
		text-align: center;
		font-size: 2.2rem;
		margin: 30px 0 100px 0;
	}
	a {
		color: $dark-grey;
		border-bottom: 1px solid $dark-grey !important;
	}
}

.home .subtext {
	margin: 20px 0 0 0;
	@include media-breakpoint-up(md) {
		margin: 30px 0 0 0;
	}
}

.section__quote,
.section__logos,
.section__intro {
	@include make-col(12);
	@include media-breakpoint-up(md) {
		@include make-col(10);
		@include make-col-offset(1);
	}
	@include media-breakpoint-up(lg) {
		@include make-col(9);
		@include make-col-offset(1.5);
	}
}

.section__quote {
	blockquote {
		margin: 0;
		@include media-breakpoint-down(sm) {
			margin-bottom: 25px;
		}
	}
}

.section {
	padding: 50px 0;
	@include media-breakpoint-up(md) {
		padding: 150px 0;
	}
}

.section--small {
	padding: 50px 0 25px 0;
	@include media-breakpoint-up(md) {
		padding: 150px 0 50px 0;
	}
}

.section__logos {
	display: none;
	@include media-breakpoint-up(md) {
		display: block;
		padding: 75px 0 60px 0;
	}
	.company-logos {
		@include reset-list();
		display: flex;
		flex-wrap: wrap;
	}

	.company-logo {
		width: calc(25% - 45px);
		margin-right: 60px;
		max-height: 60px;
		&:last-child {
			margin-right: 0;
		}
		img {
			width: 100%;
			height: 100%;
			@include object-fit(contain);
			filter: grayscale(1);
			-webkit-filter: grayscale(1);
		}
	}
}

.section__intro {
	.column:last-child {
		display: none;
		@include media-breakpoint-up(md) {
			display: block;
		}
	}
}

.section__fijnstof {
	.wysiwyg,
	.gform_confirmation_message {
		color: $dark-grey;
		font-size: 18px;
		@include averta-light();
		line-height: 1.56;
	}
	.column:last-child {
		display: block;
		@include media-breakpoint-down(sm) {
			margin-top: 35px;
		}
	}
}

.hidden {
	display: none !important;
}

//todo
.kies-aantal .product {
	.section__wrapper {
		padding-top: 50px;
	}
}
