.footer__about {
	.section__column {
		@include make-col(12);
		color: $color-white;
		padding: 50px 25px;
		@include media-breakpoint-up(md) {
			@include make-col(9);
			@include make-col-offset(1.5);
			padding: 100px 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 150px 0;
		}
	}
}

.footer__bar {
	color: $color-white;
	padding: 50px 0;
	.footer__logo {
		@include make-col(12);
		display: flex;
		justify-content: center;
	}
	.footer__nav,
	.footer__usps {
		display: none;
	}
	@include media-breakpoint-up(md) {
		padding: 75px 0;
		.footer__logo {
			@include make-col(2.5);
			@include make-col-offset(2.5);
			img {
				width: 100%;
			}
		}
		.footer__nav {
			@include make-col(3);
			@include make-col-offset(1.5);
			display: block;
			padding: 0;
		}
	}
	@include media-breakpoint-up(lg) {
		.footer__logo {
			@include make-col(1.5);
			@include make-col-offset(2.5);
		}
		.footer__nav {
			@include make-col(2);
			@include make-col-offset(0.5);
		}
		.footer__usps {
			@include make-col(2.5);
			@include make-col-offset(0.5);
			display: block;
			padding: 0;
		}
	}
}

.footer__nav .menu {
	@include reset-list;
	@include averta-semibold();
	font-size: 1.6rem;
	.menu-item {
		margin-bottom: 15px;
		&:nth-child(n + 3) {
			@include averta-regular();
		}
		&:last-child {
			margin-bottom: 0;
		}
		a {
			@include hover-focus-active {
				color: $blue;
			}
		}
	}
}

.usps {
	@include reset-list;
	.usp {
		@include averta-semibold();
		font-size: 1.4rem;
		display: flex;
		align-items: center;
		color: $primary-color;
		margin-bottom: 20px;
		.icon {
			min-width: 25px;
			min-height: 25px;
			margin-right: 15px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.column-wrapper {
	display: flex;
	flex-direction: column;
	.column {
		width: 100%;
	}
	@include media-breakpoint-up(md) {
		flex-direction: row;
		.column {
			width: calc(50% - 25px);
		}
		.column:first-of-type {
			margin-right: 50px;
		}
	}

	@include media-breakpoint-up(lg) {
		flex-direction: row;
		.column {
			width: calc(50% - 50px);
		}
		.column:first-of-type {
			margin-right: 100px;
		}
	}
}

.notice {
	text-align: center;
	position: fixed;
	top: 100px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 18px;
	@include averta-light();
	border-radius: 10px;
	padding: 50px;
	background: white;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	@include media-breakpoint-down(xs) {
		font-size: 14px;
		left: 25px;
		right: 25px;
		transform: translateX(0);
	}
	.icon {
		color: black;
		position: absolute;
		top: 12px;
		right: 12px;
		cursor: pointer;
	}
}
