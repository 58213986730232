.faq__cats,
.faq__txt {
    @include make-col(12);
    @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
    }
}

.faq__cat {
    .title--xs { margin-bottom: 15px; }
    margin: 50px 0;
    @include media-breakpoint-up(md) {
        .title--xs { margin-bottom: 35px; }
        margin: 100px 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.faq-item {
    margin-bottom: 15px;
    &:last-of-type {
        margin-bottom: 0;
    }
    .question {
        position: relative;
        padding: 15px 44px 15px 15px;
        font-size: 1.4rem;
        line-height: 1.4;
        color: $dark-grey;
        @include averta-semibold();
        border: 1px solid $grey;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        &:hover {
            cursor: pointer;
        }
        @include media-breakpoint-up(sm) {
            padding: 21px 25px 20px 25px;
            font-size: 1.6rem;
        }
    }
    .open-faq {
        position: absolute;
        right: 15px;
        top: 18px;
        display: block;
        width: 12px;
        height: 12px;
        span {
            position: absolute;
            display: block;
            background: $primary-color;
            transition: transform 0.3s ease-out;
            &:first-child {
                top: 5px;
                left: 0;
                height: 2px;
                width: 100%;
            }
            &:last-child {
                top: 0;
                left: 5px;
                height: 100%;
                width: 2px;
            }
        }
        @include media-breakpoint-up(sm) {
            right: 25px;
            top: 25px;
            width: 14px;
            height: 14px;
            span {
                &:first-child { top: 6px; }
                &:last-child { left: 6px; }
            }
        }
    }
    .answer {
        overflow: hidden;
        height: auto;
        max-height: 0;
        transition: max-height 0.3s ease-in-out;
        p {
            font-size: 1.4rem;
            line-height: 1.6;
            color: $dark-grey;
            margin-top: 15px;
            @include media-breakpoint-up(sm) {
                font-size: 1.6rem;
            }
        }
    }
}

.faq-item--open {
    .question{
        color: $primary-color;
        @include media-breakpoint-up(sm) {
            box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
        }
    }
    .open-faq span {
        &:first-child {
            transform: rotate(45deg);
        }
        &:last-child {
            transform: rotate(45deg);
        }
    }
    .answer {
        max-height:600px;
        transition: max-height 0.3s ease-in;
    }
}

.faq__txt {
    padding: 50px 50px 150px 50px;
    text-align: center;
    p {
        font-size: 1.8rem;
    }
}