// Wysiwyg styles
.wysiwyg,
#tinymce {
    p {
        font-size: 1.6rem;
        line-height: 1.4;
        @include averta-light();

    }
    strong {
        @include averta-bold();
    }
    a {
        display: inline-block;
        line-height: 1.2;
        border-bottom: 1px solid $primary-color;
        @include hover-focus-active {
            color: $blue;
            border-bottom: 1px solid $blue;
        }
    }
    h3, .title--sm {
        @include averta-bold();
        font-size: 2.2rem;
        line-height: 1.2;
        @include media-breakpoint-up(md) {
            font-size: 3.2rem;
        }
    }
    h4, .title--xs {
        @include averta-bold();
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    ul {
        @include reset-list();
        li {
            position: relative;
            &:before {
                position: absolute;
                content: '';
                background-image: url('./../images/icon_check.svg');
                background-repeat: no-repeat;
                background-size: contain;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 25px;
                height: 25px;
            }
            p {
                @include averta-semibold();
                font-size: 1.6rem;
                line-height: 2;
                margin-left: 35px;
            }
        }
    }
    .subtext p {
        @include averta-light();
        font-size: 1.6rem;
        line-height: 1.2;
        text-align: left;
        color: $dark-grey;
        @include media-breakpoint-up(md) {
            text-align: center;
            font-size: 2.2rem;
        }
        a {
            color: $dark-grey;
            border-bottom: 1px solid $dark-grey !important;
        }
    }
    .subtext--sm p {
        @include averta-light();
        font-size: 1.6rem;
        line-height: 1.2;
        text-align: left;
        color: $dark-grey;
        @include media-breakpoint-up(md) {
            text-align: center;
            font-size: 2rem;
            margin-bottom: 25px;
        }
        a {
            color: $dark-grey;
            border-bottom: 1px solid $dark-grey !important;
        }
    }
}