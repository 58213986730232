.section__wrapper {
	padding: 50px 0;
	@include media-breakpoint-up(md) {
		padding: 150px 0;
		align-items: center;
	}
}

.section__wrapper--top {
	@include media-breakpoint-up(md) {
		padding: 100px 0 150px 0;
	}
}

.intro__cta-btn .btn--green {
	margin-top: 25px;
	@include media-breakpoint-up(sm) {
		margin-top: 50px;
	}
	@include media-breakpoint-up(md) {
		margin-top: 100px;
	}
}

.section__img {
	@include make-col(12);
	margin-bottom: 50px;
	@include media-breakpoint-up(md) {
		margin-bottom: 0;
		@include make-col(5);
		@include make-col-offset(1);
	}
	img {
		width: 100%;
		height: auto;
		object-fit: contain;
	}
}

.section__txt {
	@include make-col(12);
	@include media-breakpoint-up(md) {
		@include make-col(4);
		@include make-col-offset(1);
	}
	.title--sm {
		margin-bottom: 15px;
	}
	a {
		margin-top: 20px;
		border-bottom: none !important;
	}
}

.row--reverse {
	@include media-breakpoint-up(md) {
		flex-direction: row-reverse;
		justify-content: flex-end;
		.section__txt {
			@include make-col(4);
			@include make-col-offset(1);
		}
		.section__img {
			@include make-col(5);
			@include make-col-offset(1);
		}
		.btn--green {
			margin-top: 150px;
		}
	}
	.btn--green {
		margin-top: 50px;
	}
}
