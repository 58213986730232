.product {
	.product-note,
	.title--md {
		//@include make-col(12);
		@include media-breakpoint-up(md) {
			@include make-col(8);
			@include make-col-offset(2);
		}
	}
	.product-note p {
		font-size: 1.8rem;
		text-align: center;
		margin-top: 0;
	}
	.section__wrapper {
		flex-direction: column;
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 0;
	}
	@include media-breakpoint-down(sm) {
		max-width: none;
	}
}

.product__nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.btn--product {
		margin-bottom: 10px;
		margin-right: 10px;
		&:last-child {
			margin-bottom: 0;
			margin-right: 0;
		}
	}
	@include media-breakpoint-up(sm) {
		flex-direction: row;
		.btn--product {
			margin-bottom: 0;
		}
	}
}

.product__wrapper {
	padding: 0;
}

.carousel {
	margin: 50px 0;
	display: none;
	@include make-col(12);
	@include media-breakpoint-up(lg) {
		@include make-col(10);
		@include make-col-offset(1);
	}
	&:after {
		content: 'flickity';
		display: none;
		@include media-breakpoint-up(md) {
			content: '';
		}
	}
	.carousel-cell {
		width: calc(100% - 92px);
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		border-radius: 15px;
		box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
		margin: 10px 15px 10px 0;
		&:last-child {
			margin-right: 0;
		}
		@include media-breakpoint-up(sm) {
			width: calc(100% - 240px);
			margin: 10px 50px 10px 0;
		}
		@include media-breakpoint-up(md) {
			margin-right: 35px;
		}
		@include media-breakpoint-up(lg) {
			margin-right: 35px;
			width: calc(33% - 20px);
		}
	}
}

.flickity-viewport {
	padding-top: 10px;
}

.product__nav {
	.btn--product.is-selected {
		color: $color-white;
		border: 1px solid $blue;
		background-color: $blue;
		outline: none;
		opacity: 1;
		transition: All 300ms ease-in-out;
	}
}

.product__amount {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 25px;
	button {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		&:disabled {
			opacity: 0.2;
		}
	}
	.add {
		color: white;
		background: $blue;
		border: solid 1px $blue;
	}
	.subtract {
		width: 30px;
		height: 30px;
		border: solid 1px #bac3d1;
		background: none;
	}
	input[type='number'] {
		text-align: center;
		color: $primary-color;
		margin: 0 15px;
		padding: 9px 0;
		font-size: 20px;
		border-radius: 3px;
		border: solid 1px #bac3d1;
		max-width: 58px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}

.carousel--active {
	display: block;
	@include media-breakpoint-up(md) {
		display: flex;
	}
}

.product__head {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 35px 10px;
	text-align: center;
	.period {
		@include averta-light();
		font-size: 1.4rem;
		line-height: 1.2;
		color: $dark-grey;
	}
	.icon-spacer {
		width: 28px;
		height: 28px;
		margin-top: 10px;
	}
}

.product__footer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 35px 0;
	.price {
		@include averta-regular();
		font-size: 2.6rem;
		line-height: 1.2;
		margin-bottom: 10px;
		span {
			@include averta-bold();
		}
	}
	.period {
		@include averta-regular();
		font-size: 1.2rem;
		line-height: 1.2;
	}
}
