.page404 {
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    .section__row {
        justify-content: center;
    }
    .subtext {
        text-align: center !important;
    }
}

.title--404 {
    text-align: center;
    position: relative;
    font-size: 8rem;
    &:after {
        position: absolute;
        content: "";
        width: 270px;
        height: 80px;
        background-image: url('./../images/element.svg');
        background-repeat: no-repeat;
        background-size: contain;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -9;
    }
    @include media-breakpoint-up(md) {
        font-size: 20rem;
        &:after {
            width: 600px;
            height: 200px;
        }
    }
}