.header-bar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	background-color: $color-white;
	z-index: 999;
	display: flex;
	padding: 20px 25px;
	.logo {
		opacity: 1;
		height: 40px;
		transition: opacity 4ms ease-in-out;
		transition-delay: 0.3s;
		img {
			width: auto;
			height: 100%;
			object-fit: contain;
		}
		@include media-breakpoint-up(md) {
			height: 44px;
		}
	}
	.logo--center {
		margin-left: 15px;
		@include media-breakpoint-up(md) {
			margin-left: 0;
			margin: 30px auto 0 auto;
		}
	}
	@include media-breakpoint-up(md) {
		position: relative;
		padding: 20px 0;
		@include make-col(12);
	}
	@include media-breakpoint-up(lg) {
		@include make-col(11);
		@include make-col-offset(0.5);
	}
}

.nav-desktop {
	display: none;
	@include media-breakpoint-up(md) {
		display: flex;
		flex: 1;
		justify-content: flex-end;
		align-items: center;
		.menu {
			display: flex;
			@include reset-list;
			@include averta-semibold();
			font-size: 1.6rem;
			line-height: 1.4;
			.menu-item {
				display: flex;
				align-items: center;
				margin-right: 20px;
				transition: all 300ms ease-in-out;
				&:nth-child(3) {
					background-color: $green;
					padding: 0 20px;
					border-radius: 32.5px;
					height: 40px;
					transition: all 300ms ease-in-out;
					a {
						color: $color-white;
					}
					a:hover {
						color: $color-white;
					}
					&:hover {
						background-color: $green-hover;
					}
					&.active a {
						border-bottom: none;
					}
				}
				&:last-child {
					margin-right: 0;
				}
				a:hover {
					color: $blue;
					transition: all 300ms ease-in-out;
				}
			}
			.active a {
				border-bottom: 2px solid $blue;
			}
		}
	}
}

.nav-mobile {
	position: fixed;
	top: 80px;
	bottom: 0;
	left: 0;
	flex-direction: column;
	background: $color-white;
	padding: 0 35px 50px 35px;
	align-items: flex-start;
	transform: translate3d(0, -100%, 0);
	transition: transform 0.4s ease;
	z-index: 9;
	width: 100vw;

	@include media-breakpoint-up(md) {
		display: none;
	}
	.menu {
		@include reset-list;
		@include averta-semibold();
		font-size: 1.6rem;
		.menu-item {
			margin-bottom: 25px;
			&:nth-child(n + 3) {
				@include averta-regular();
				margin-bottom: 20px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&.open {
		transform: translate3d(0, 0, 0);
		transition: transform 0.4s ease;
	}
	.btn {
		justify-content: center;
		position: absolute;
		bottom: 35px;
		left: 35px;
		right: 35px;
		width: auto;
	}
}

.menu-active {
	overflow: hidden;
	.logo {
		opacity: 0;
		transition: opacity 4ms ease-in-out;
	}
}
