.video__content {
	position: relative;
	margin-left: 0;
	margin-right: 0;
	@include media-breakpoint-down(xs) {
		padding: 0 25px;
	}
}

.section__video {
	position: relative;
	@include make-col(12);
	margin-bottom: 0;
	border-radius: 10px;
	overflow: hidden;
	padding: 0;
	iframe {
		width: 100%;
		height: 200px;
		display: block;
		position: relative;
	}
	@include media-breakpoint-up(sm) {
		iframe {
			height: 350px;
		}
	}
	@include media-breakpoint-up(lg) {
		@include make-col(10);
		@include make-col-offset(1);
		iframe {
			height: 500px;
		}
	}
}

.bg--half {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		height: 50%;
		bottom: 0;
		left: 0;
		width: 100%;
		background: $grey;
		z-index: -1;
	}
}

.video__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: $primary-color;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 1;
	transition: opacity 0s linear 0s;
	border-radius: 10px;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50%;
		transform: translate(-50%, -50%);
	}
	button {
		z-index: 5;
		background: none;
		border: none;
	}
	svg {
		width: 55px;
		height: 55px;
		@include media-breakpoint-up(md) {
			width: 100px;
			height: 100px;
		}
	}
	.js_video-playing & {
		opacity: 0;
		transition: opacity 0.3s linear 0s;
		pointer-events: none;
	}
}

.js_video-playing {
	cursor: pointer;
}
